// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../../node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../../node_modules/rescript/lib/es6/string.js";
import * as Js_array from "../../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../../shared/utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

var partial_arg = "components.TargetChecklistItem";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function title(t) {
  return t.title;
}

function kind(t) {
  return t.kind;
}

function optional(t) {
  return t.optional;
}

function actionStringForKind(kind) {
  if (typeof kind !== "number") {
    return t(undefined, undefined, "action_string_choose_from_list");
  }
  switch (kind) {
    case /* Files */0 :
        return t(undefined, undefined, "action_string_upload_files");
    case /* Link */1 :
        return t(undefined, undefined, "action_string_attach_link");
    case /* ShortText */2 :
        return t(undefined, undefined, "action_string_write_short_text");
    case /* LongText */3 :
        return t(undefined, undefined, "action_string_write_long_text");
    case /* AudioRecord */4 :
        return t(undefined, undefined, "action_string_record_audio");
    
  }
}

function kindAsString(kind) {
  if (typeof kind !== "number") {
    return "multiChoice";
  }
  switch (kind) {
    case /* Files */0 :
        return "files";
    case /* Link */1 :
        return "link";
    case /* ShortText */2 :
        return "shortText";
    case /* LongText */3 :
        return "longText";
    case /* AudioRecord */4 :
        return "audio";
    
  }
}

function make(title, kind, optional) {
  return {
          title: title,
          kind: kind,
          optional: optional
        };
}

function updateTitle(title, t) {
  return {
          title: title,
          kind: t.kind,
          optional: t.optional
        };
}

function updateKind(kind, t) {
  return {
          title: t.title,
          kind: kind,
          optional: t.optional
        };
}

function updateOptional(optional, t) {
  return {
          title: t.title,
          kind: t.kind,
          optional: optional
        };
}

function removeItem(index, array) {
  return Js_array.filteri((function (_item, i) {
                return i !== index;
              }), array);
}

var moveUp = ArrayUtils.swapUp;

var moveDown = ArrayUtils.swapDown;

function copy(index, array) {
  return ArrayUtils.flattenV2(Js_array.mapi((function (item, i) {
                    if (i === index) {
                      return [
                              item,
                              item
                            ];
                    } else {
                      return [item];
                    }
                  }), array));
}

function removeMultichoiceOption(choiceIndex, t) {
  var match = t.kind;
  if (typeof match === "number") {
    return t;
  }
  var updatedChoices = ArrayUtils.flattenV2($$Array.mapi((function (i, choice) {
              if (i === choiceIndex) {
                return [];
              } else {
                return [choice];
              }
            }), match._0));
  return updateKind(/* MultiChoice */{
              _0: updatedChoices,
              _1: match._1
            }, t);
}

function addMultichoiceOption(t) {
  var match = t.kind;
  if (typeof match === "number") {
    return t;
  }
  var updatedChoices = Js_array.concat([""], match._0);
  return updateKind(/* MultiChoice */{
              _0: updatedChoices,
              _1: match._1
            }, t);
}

function updateMultichoiceOption(choiceIndex, newOption, t) {
  var match = t.kind;
  if (typeof match === "number") {
    return t;
  }
  var updatedChoices = Js_array.mapi((function (choice, i) {
          if (i === choiceIndex) {
            return newOption;
          } else {
            return choice;
          }
        }), match._0);
  return updateKind(/* MultiChoice */{
              _0: updatedChoices,
              _1: match._1
            }, t);
}

function updateAllowMultiple(allowMultiple, t) {
  var match = t.kind;
  if (typeof match === "number") {
    return t;
  } else {
    return updateKind(/* MultiChoice */{
                _0: match._0,
                _1: allowMultiple
              }, t);
  }
}

function isFilesKind(t) {
  var match = t.kind;
  return match === 0;
}

function isValidChecklistItem(t) {
  var titleValid = t.title.trim().length >= 1;
  var match = t.kind;
  if (typeof match === "number") {
    return titleValid;
  }
  var allowMultiple = match._1;
  if (ArrayUtils.isEmpty(Js_array.filter((function (choice) {
                return $$String.trim(choice) === "";
              }), match._0)) && titleValid && allowMultiple === true) {
    return true;
  } else {
    return allowMultiple === false;
  }
}

function decodeMetadata(kind, json) {
  return /* MultiChoice */{
          _0: Json_decode.field("choices", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          _1: Json_decode.field("allowMultiple", Json_decode.bool, json)
        };
}

function decode(json) {
  var otherKind = Json_decode.field("kind", Json_decode.string, json);
  var tmp;
  switch (otherKind) {
    case "audio" :
        tmp = /* AudioRecord */4;
        break;
    case "files" :
        tmp = /* Files */0;
        break;
    case "link" :
        tmp = /* Link */1;
        break;
    case "longText" :
        tmp = /* LongText */3;
        break;
    case "multiChoice" :
        tmp = Json_decode.field("metadata", (function (param) {
                return decodeMetadata("MultiChoice", param);
              }), json);
        break;
    case "shortText" :
        tmp = /* ShortText */2;
        break;
    default:
      Rollbar.error("Unknown kind: " + (otherKind + " received in CurriculumEditor__TargetChecklistItem"));
      tmp = /* LongText */3;
  }
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          kind: tmp,
          optional: Json_decode.field("optional", Json_decode.bool, json)
        };
}

function encodeMetadata(kind) {
  if (typeof kind === "number") {
    return Json_encode.object_(/* [] */0);
  } else {
    return Json_encode.object_({
                hd: [
                  "choices",
                  kind._0
                ],
                tl: {
                  hd: [
                    "allowMultiple",
                    kind._1
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "kind",
                kindAsString(t.kind)
              ],
              tl: {
                hd: [
                  "title",
                  t.title
                ],
                tl: {
                  hd: [
                    "optional",
                    t.optional
                  ],
                  tl: {
                    hd: [
                      "metadata",
                      encodeMetadata(t.kind)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function encodeChecklist(checklist) {
  return Json_encode.array(encode, checklist);
}

var longText = {
  title: "",
  kind: /* LongText */3,
  optional: false
};

export {
  t ,
  title ,
  kind ,
  optional ,
  actionStringForKind ,
  kindAsString ,
  make ,
  updateTitle ,
  updateKind ,
  updateOptional ,
  removeItem ,
  moveUp ,
  moveDown ,
  copy ,
  removeMultichoiceOption ,
  addMultichoiceOption ,
  updateMultichoiceOption ,
  updateAllowMultiple ,
  longText ,
  isFilesKind ,
  isValidChecklistItem ,
  decodeMetadata ,
  decode ,
  encodeMetadata ,
  encode ,
  encodeChecklist ,
}
/* I18n Not a pure module */
