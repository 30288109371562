import { notifyEvent } from "./utils/NotifyStudentVideoEvent.bs.js"

let player
// let timer = Js.Global.setInterval;
let timeSpent = [];
let sended = false;

window.onYouTubeIframeAPIReady = function() {
  var youtubeNodes = document.querySelectorAll('[id^="gt-course-youtube-video-"]');
  var playerList = Array.from(youtubeNodes).map(node => node.id);
  for (var i = 0; i < playerList.length; i++) {
    player = createPlayer(playerList[i]);
  };
}

const onPlayerStateChange = (event, videoId, targetId) => {
  if(event === 1) {
    if(!timeSpent.length) {
      var duration = parseInt(player.getDuration());
      for (var i = 0; i < duration; i++) {
        timeSpent.push(false)
      }
    }

    var timer = setInterval(function() {
      timeSpent[parseInt(player.getCurrentTime())] = true;

      var percent = 0;
      for (var i = 0; i < timeSpent.length; i++) {
        if(timeSpent[i]) {
          percent++;
        }
      }
      percent = Math.round(percent / timeSpent.length * 100)
      console.log(percent)
      if (percent >= 90 && !sended) {
        var duration = parseInt(player.getDuration());
        notifyEvent(videoId, targetId, "watched", duration)
        sended = true
      }
    },1000);
  } else {
    clearInterval(timer);
  }
}

export function initPlayer(targetId) {
  var tag = document.createElement('script');
  tag.id = "course-youtube-player"
  tag.src = "https://www.youtube.com/iframe_api";
  tag.dataset.targetId = targetId
  document.head.appendChild(tag);
}

export function createPlayer(videoId) {
  var tag = document.getElementById("course-youtube-player");
  var targetId = tag.dataset.targetId;
  return new YT.Player(videoId, {
    events: {
      'onStateChange': (e) => onPlayerStateChange(e.data, videoId, targetId)
    }
  })
}
