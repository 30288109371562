import Player from "@vimeo/player";

export function initPlayer(iframe, targetId, notify) {
  var player = new Player(iframe);
  var sended = false;

  player.getDuration().then(function(duration) {
    const durationOption = duration ? Math.floor(duration) : null; // Convert float to int and wrap in option
    console.log(durationOption)

    player.on("timeupdate", function (data) {
      console.log(data['percent'])
      if (data['percent'] >= 0.900 && !sended) {
        notify("videoId", targetId, "watched", durationOption);
        sended = true;
      }
    });
  }).catch(function(error) {
    console.error("Error getting duration:", error);
  });
}
