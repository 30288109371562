// Generated by ReScript, PLEASE EDIT WITH CARE

import * as YoutubePlayerJs from "../youtubePlayer.js";

function initPlayer(prim) {
  YoutubePlayerJs.initPlayer(prim);
}

function init(targetId) {
  YoutubePlayerJs.initPlayer(targetId);
}

function createPlayer(videoId) {
  YoutubePlayerJs.createPlayer(videoId);
}

export {
  initPlayer ,
  init ,
  createPlayer ,
}
/* ../youtubePlayer.js Not a pure module */
