// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var GradeLabelsEmpty = /* @__PURE__ */Caml_exceptions.create("GradeLabel.GradeLabelsEmpty");

function decode(json) {
  return {
          label: Json_decode.field("label", Json_decode.string, json),
          grade: Json_decode.field("grade", Json_decode.$$int, json)
        };
}

function grade(t) {
  return t.grade;
}

function label(t) {
  return t.label;
}

function labelFor(gradeLabels, grade) {
  return List.find((function (gradeLabel) {
                return gradeLabel.grade === grade;
              }), gradeLabels).label;
}

function create(grade, label) {
  return {
          label: label,
          grade: grade
        };
}

function empty(grade) {
  return {
          label: "",
          grade: grade
        };
}

function update(label, t) {
  return {
          label: label,
          grade: t.grade
        };
}

function asJsObject(t) {
  return {
          grade: t.grade,
          label: t.label
        };
}

function valid(t) {
  return t.label.trim().length >= 1;
}

function makeFromJs(rawGradeLabel) {
  return {
          label: rawGradeLabel.label,
          grade: rawGradeLabel.grade
        };
}

function maxGrade(gradeLabels) {
  var aux = function (_max, _remains) {
    while(true) {
      var remains = _remains;
      var max = _max;
      if (!remains) {
        return max;
      }
      _remains = remains.tl;
      _max = Math.max(remains.hd.grade, max);
      continue ;
    };
  };
  var validGrade = aux(0, gradeLabels);
  if (validGrade !== 0) {
    return validGrade;
  }
  Rollbar.error("GradeLabel.maxGrade received an empty list of gradeLabels");
  throw {
        RE_EXN_ID: GradeLabelsEmpty,
        Error: new Error()
      };
}

export {
  GradeLabelsEmpty ,
  decode ,
  grade ,
  label ,
  labelFor ,
  create ,
  empty ,
  update ,
  asJsObject ,
  valid ,
  makeFromJs ,
  maxGrade ,
}
/* No side effect */
