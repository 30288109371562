// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Tooltip from "./Tooltip.bs.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.ClickToCopy";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function performCopy(_copy, setState, _event) {
  ((navigator.clipboard.writeText(_copy)));
  return Curry._1(setState, (function (param) {
                return /* Copied */1;
              }));
}

function refresh(setState, _event) {
  return Curry._1(setState, (function (param) {
                return /* ReadyToCopy */0;
              }));
}

function ClickToCopy(Props) {
  var copy = Props.copy;
  var tooltipPositionOpt = Props.tooltipPosition;
  var classNameOpt = Props.className;
  var tooltipClassName = Props.tooltipClassName;
  var children = Props.children;
  var tooltipPosition = tooltipPositionOpt !== undefined ? tooltipPositionOpt : "Top";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState(function () {
        return /* ReadyToCopy */0;
      });
  var setState = match[1];
  var tip = match[0] ? t(undefined, undefined, "copied") : t(undefined, undefined, "copy");
  var tmp = {
    tip: tip,
    position: tooltipPosition,
    children: children
  };
  if (tooltipClassName !== undefined) {
    tmp.className = Caml_option.valFromOption(tooltipClassName);
  }
  return React.createElement("div", {
              className: "cursor-pointer " + className,
              onClick: (function (param) {
                  return performCopy(copy, setState, param);
                }),
              onMouseLeave: (function (param) {
                  return Curry._1(setState, (function (param) {
                                return /* ReadyToCopy */0;
                              }));
                })
            }, React.createElement(Tooltip.make, tmp));
}

var make = ClickToCopy;

export {
  str ,
  t ,
  performCopy ,
  refresh ,
  make ,
}
/* I18n Not a pure module */
