// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_promise from "../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as GraphqlQuery from "./GraphqlQuery.bs.js";

var Raw = {};

var query = "mutation NotifyStudentVideoEvent($videoId: String!, $event: String!, $targetId: String!, $duration: Int)  {\nnotifyStudentVideoEvent(videoId: $videoId, targetId: $targetId, event: $event, duration: $duration)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.notifyStudentVideoEvent;
  return {
          notifyStudentVideoEvent: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.notifyStudentVideoEvent;
  var value$2 = value$1.success;
  var notifyStudentVideoEvent = {
    success: value$2
  };
  return {
          notifyStudentVideoEvent: notifyStudentVideoEvent
        };
}

function serializeVariables(inp) {
  var a = inp.duration;
  return {
          videoId: inp.videoId,
          event: inp.event,
          targetId: inp.targetId,
          duration: a !== undefined ? a : undefined
        };
}

function makeVariables(videoId, $$event, targetId, duration, param) {
  return {
          videoId: videoId,
          event: $$event,
          targetId: targetId,
          duration: duration
        };
}

var NotifyStudentVideoEvent_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var NotifyStudentVideoEvent_Graphql_error = include.Graphql_error;

var NotifyStudentVideoEvent_decodeNotification = include.decodeNotification;

var NotifyStudentVideoEvent_decodeObject = include.decodeObject;

var NotifyStudentVideoEvent_decodeNotifications = include.decodeNotifications;

var NotifyStudentVideoEvent_decodeErrors = include.decodeErrors;

var NotifyStudentVideoEvent_flashNotifications = include.flashNotifications;

var NotifyStudentVideoEvent_sendQuery = include.sendQuery;

var NotifyStudentVideoEvent_query = include.query;

var NotifyStudentVideoEvent_fetch = include.$$fetch;

var NotifyStudentVideoEvent = {
  NotifyStudentVideoEvent_inner: NotifyStudentVideoEvent_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: NotifyStudentVideoEvent_Graphql_error,
  decodeNotification: NotifyStudentVideoEvent_decodeNotification,
  decodeObject: NotifyStudentVideoEvent_decodeObject,
  decodeNotifications: NotifyStudentVideoEvent_decodeNotifications,
  decodeErrors: NotifyStudentVideoEvent_decodeErrors,
  flashNotifications: NotifyStudentVideoEvent_flashNotifications,
  sendQuery: NotifyStudentVideoEvent_sendQuery,
  query: NotifyStudentVideoEvent_query,
  $$fetch: NotifyStudentVideoEvent_fetch,
  make: make
};

function notifyEvent(videoId, targetId, $$event, duration) {
  Js_promise.then_((function (_response) {
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            videoId: videoId,
            event: $$event,
            targetId: targetId,
            duration: duration
          }));
}

export {
  NotifyStudentVideoEvent ,
  notifyEvent ,
}
/* include Not a pure module */
