// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as VimeoPlayerJs from "../vimeoPlayer.js";
import * as NotifyStudentVideoEvent from "./NotifyStudentVideoEvent.bs.js";

function initPlayer(prim0, prim1, prim2) {
  return VimeoPlayerJs.initPlayer(prim0, prim1, prim2);
}

function init(targetId) {
  var iframes = (Array.from(document.querySelectorAll('iframe')));
  if (!iframes) {
    return ;
  }
  var vimeoFrames = (Array.from(iframes).filter((iframe) => /player\.vimeo/.test(iframe.src)));
  Belt_Array.forEach(vimeoFrames, (function (iframe) {
          VimeoPlayerJs.initPlayer(iframe, targetId, NotifyStudentVideoEvent.notifyEvent);
        }));
}

export {
  initPlayer ,
  init ,
}
/* ../vimeoPlayer.js Not a pure module */
