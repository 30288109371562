// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../utils/DateFns.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as OptionUtils from "../utils/OptionUtils.bs.js";

function serialNumber(t) {
  return t.serialNumber;
}

function issuedTo(t) {
  return t.issuedTo;
}

function profileName(t) {
  return t.profileName;
}

function nationalId(t) {
  return t.nationalId;
}

function issuedAt(t) {
  return t.issuedAt;
}

function courseName(t) {
  return t.courseName;
}

function imageUrl(t) {
  return t.imageUrl;
}

function margin(t) {
  return t.margin;
}

function nationalIdOffsetTop(t) {
  return t.nationalIdOffsetTop;
}

function nameOffsetTop(t) {
  return t.nameOffsetTop;
}

function fontSize(t) {
  return t.fontSize;
}

function nationalIdFontSize(t) {
  return t.nationalIdFontSize;
}

function qrCorner(t) {
  return t.qrCorner;
}

function nationalIdVisibility(t) {
  return t.nationalIdVisibility;
}

function qrScale(t) {
  return t.qrScale;
}

function make(serialNumber, issuedTo, profileName, nationalId, issuedAt, courseName, imageUrl, margin, nationalIdOffsetTop, fontSize, nationalIdFontSize, nameOffsetTop, qrCorner, nationalIdVisibility, qrScale) {
  return {
          serialNumber: serialNumber,
          issuedTo: issuedTo,
          profileName: profileName,
          nationalId: nationalId,
          issuedAt: issuedAt,
          courseName: courseName,
          imageUrl: imageUrl,
          margin: margin,
          fontSize: fontSize,
          nationalIdFontSize: nationalIdFontSize,
          nameOffsetTop: nameOffsetTop,
          nationalIdOffsetTop: nationalIdOffsetTop,
          qrCorner: qrCorner,
          nationalIdVisibility: nationalIdVisibility,
          qrScale: qrScale
        };
}

function decode(json) {
  return make(Json_decode.field("serialNumber", Json_decode.string, json), Json_decode.field("issuedTo", Json_decode.string, json), Json_decode.field("profileName", Json_decode.string, json), Json_decode.field("nationalId", Json_decode.string, json), Json_decode.field("issuedAt", DateFns.decodeISO, json), Json_decode.field("courseName", Json_decode.string, json), Json_decode.field("imageUrl", Json_decode.string, json), Json_decode.field("margin", Json_decode.$$int, json), Json_decode.field("nationalIdOffsetTop", Json_decode.$$int, json), Json_decode.field("fontSize", Json_decode.$$int, json), Json_decode.field("nationalIdFontSize", Json_decode.$$int, json), Json_decode.field("nameOffsetTop", Json_decode.$$int, json), OptionUtils.mapWithDefault((function (corner) {
                    switch (corner) {
                      case "BottomLeft" :
                          return "BottomLeft";
                      case "BottomRight" :
                          return "BottomRight";
                      case "Hidden" :
                          return "Hidden";
                      case "TopLeft" :
                          return "TopLeft";
                      case "TopRight" :
                          return "TopRight";
                      default:
                        Rollbar.warning("Encountered unknown value for qrCorder: " + (corner + " while decoding props."));
                        return "Hidden";
                    }
                  }), "Hidden", Json_decode.optional((function (param) {
                        return Json_decode.field("qrCorner", Json_decode.string, param);
                      }), json)), Json_decode.field("nationalIdVisibility", Json_decode.bool, json), Json_decode.field("qrScale", Json_decode.$$int, json));
}

export {
  serialNumber ,
  issuedTo ,
  profileName ,
  nationalId ,
  issuedAt ,
  courseName ,
  imageUrl ,
  margin ,
  nationalIdOffsetTop ,
  nameOffsetTop ,
  fontSize ,
  nationalIdFontSize ,
  qrCorner ,
  nationalIdVisibility ,
  qrScale ,
  make ,
  decode ,
}
/* DateFns Not a pure module */
